:root {
  --background: #12333E;
  --background-t: #12333e5b;
  --accent: #1A7C92;
  --accent-t: #1a7c9220;
  --content: rgba(255, 255, 255, 0.9);
  --content-t: rgba(255, 255, 255, 0.05);
  --content-d: rgba(0, 0, 0, 0.05);
  --text: #333333;
  --text-grey: #aaaaaa;
  --text-white: #ffffff;
}

* {
  box-sizing: border-box;
}

body {
  background: var(--background) url('Background.svg') repeat-y;
  background-size: 100%;
  color: var(--text-white);
}

.App {
  position: relative;
  padding-bottom: 165px;
  min-height: calc(100vh + 165px);
}

.responsive-container {
  display: flex;
  justify-content: center;
}
.responsive-container > .responsive-inner {
  width: 100%;
  max-width: 768px;
  margin: 0px 16px;
}

.m-0-32 {
  margin: 0px min(48px, 32px + 1vw);
}
.content {
  background-color: var(--content);
  color: var(--text);
  padding: min(48px, 32px + 1vw);
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.25);
  margin-bottom: 32px;
  border-radius: 16px;
}
@media(min-width:768px)
{
  .responsive-container > .responsive-inner {
    width: 768px;
  }
  .content {
    border-radius: 24px;
  }
}

.text-page-title {
  font-size: 32px;
  font-weight: 800;
  letter-spacing: -1px;
}

.text-section-title {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -1px;
}
.content .text-section-title {
  color: var(--background);
}

.text-content, p {
  font-size: min(calc(12px + 1vw), 16px);
}

.text-page-type {
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
}
.text-post-date {
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 0px;
  margin: -8px min(48px, 32px + 1vw);
}

button:not(.no-style), a.button {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--accent);
  box-shadow: inset 0 0 24px var(--content-t);
  color: var(--text-white);
  outline: none;
  border: none;
  border-radius: 24px;
  padding: 12px 24px;
  min-width: 150px;
  text-decoration: none;
}
button.ghost, a.button.ghost {
  background: none;
  border: 2px solid #FFF;
  box-shadow: inset 0 0 24px #0000005b;
}
button.white, a.button.white {
  background-color: var(--content);
  box-shadow: inset 0 0 24px #ffffff;
  color: var(--text)
}
button[disabled] {
  background-color: var(--text-grey);
}
button.no-style {
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
}
form p {
  padding: 0px;
  margin-bottom: 24px;
}
form button {
  width: 100%;
  padding: 6px;
}

.text-post-title {
  font-size: 20px;
  font-weight: 800;
}
footer {
  text-align: center;
  opacity: 0.75;
  position: absolute;
  bottom: 0;
  padding-bottom: 24px;
  width: 100%;
}
footer > div {
  margin-bottom: 8px;
}
footer a {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: var(--text-white);
}

header {
  z-index: 9999;
  background: var(--background) url('Background.svg') repeat-y;
  background-size: 100%;
  position: fixed;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header.shadow {
  box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.25);
}
.navigation {
  position: fixed;
  display: flex;
  top: -100vh;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  z-index: 1;
  background-color: var(--background);
  background-size: 100%;
  transition: top 0.5s;
  flex-direction: column;
}

.navigation.menu-visible {
  top: 0vh;
  transition: top 0.5s;
}

.navigation > .controls {
  padding: 24px 20px;
  display: flex;
  justify-content: space-between;
}

.navigation > .text-page-type {
  margin: 16px;
}

.navigation > .navigation-content {
  background-color: var(--accent-t);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navigation > .navigation-content > .logo {
  text-align: center;
  opacity: 0.1;
}
.navigation > .navigation-content > .logo > img {
  width: 50%;
  max-width: 200px;
}
@media(max-height: 550px){
  .navigation > .navigation-content > .logo > img {
    display: none;
  }
}
.navigation > .navigation-content > .items {
  padding: 16px;
}
.navigation > .navigation-content > .items > div {
  text-align: center;
  margin-bottom: 16px;
}
.page-title {
  padding: 40px min(48px, 32px + 1vw) 24px min(48px, 32px + 1vw);
}
.page-title.with-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
form {
  padding: 0px min(48px, 32px + 1vw);
  margin-bottom: 24px;
}
form > * {
  margin-bottom: 8px;
}
form label, form input, form textarea {
  display: block;
  width: 100%;
}
form label {
  display: flex;
  align-items: center;
}
form label img {
  margin-left: 8px;
}
form input, form textarea {
  padding: 12px 12px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: var(--content);
}
form input, form input::placeholder, form textarea, form textarea::placeholder {
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
form textarea {
  min-height: 125px;
}
.loading-content {
  text-align: center;
}
.loading-content > img.cloud {
  opacity: 0.25;
  animation: fade 4s linear infinite;
  color: #ddd;
}
img.spinner {
  opacity: 0.25;
  animation: rotate 2s linear infinite;
  color: #ddd;
}
img.spinner.small {
  width: 12px;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullscreen img {
  width: 50%;
  max-width: 400px;
}

.fullscreen a {
  margin-bottom: 16px;
}

.space {
  margin-bottom: 32px;
}

.blog-post {
  display: flex;
  align-items: stretch;
  margin: 16px 16px 32px 16px;
}

.blog-post-list-indicator {
  width: 10px;
  border-radius: 24px;
  background-color: var(--accent);
  margin-right: 16px;
  min-height: 150px;
}

.blog-post:nth-of-type(2n) > .blog-post-list-indicator
{
  background-color: var(--content);
}

.blog-post-content {
  margin: 16px 16px 16px 0px;
  flex: 1;
}

.blog-post .blog-post-content > *:not(:last-child):not(.text-page-type) {
  margin-bottom: 16px;
}

.blog-post .blog-post-content > *:not(:last-child):not(.text-page-type).text-content {
  margin-bottom: 32px;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.blog-post-adverts {
  padding: min(48px, 32px + 1vw);
}

.blog-post-adverts > .blog-post-advert {
  text-decoration: none;
  display: flex;
  background-color: var(--content);
  color: var(--text);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
}

.blog-post-adverts > .blog-post-advert > .blog-post-list-indicator {
  min-height: 50px;
  border-radius: 0px;
}

.blog-post-adverts > .blog-post-advert > .blog-post-content > * {
  margin-bottom: 4px;
}

.blog-post-adverts > .text-page-type {
  margin-bottom: 8px;
}

.blog-post-adverts > .blog-post-advert > .blog-post-content {
  margin: 8px 8px 8px 0px;
  flex: 1;
}

.loading {
  min-height: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading > img.spinner {
  width: 25%;
  max-width: 250px;
}
.feature-image {
  position: relative;
  margin-bottom: 32px;
}
.feature-image img {
  width: 100%;
}
.feature-image > .feature-image-bg {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  padding: min(48px, 32px + 1vw);

}
.feature-image > .feature-image-bg > img {
  filter: blur(16px);
  -webkit-filter: blur(16px);
  border-radius: 8px;
  opacity: 0.25;
}
.feature-image > .feature-image-fg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: min(48px, 32px + 1vw);
}
.feature-image > .feature-image-fg > img {
  border-radius: 8px;
  box-shadow: 0px 0px 25px 2px rgba(0,0,0,0.25);
}

.message {
  margin: 32px;
  background-color: var(--accent-t);
  color: var(--text-white);
  border-radius: 8px;
  padding: 16px;
  text-align: center;
}

.mailto {
  padding: 8px min(48px, 32px + 1vw);
  margin-bottom: min(48px, 32px + 1vw);
}
.mailto > p {
  padding: 0px;
}
.mailto > a {
  width: 100%;
}

h3, p {
  padding: 0px min(48px, 32px + 1vw);
}
.content p, .content h3 {
  padding: 0px;
}
h3 {
  margin-top: min(48px, 32px + 1vw);
}
.content .email {
  font-weight: 400;
  width: 100%;
  background-color: var(--background);
  padding: 8px 16px;
  color: var(--text-white);
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  font-size: min(calc(0.4em + 1vw), 0.85em);
  margin-bottom: 16px;
}
.content .email img {
  width: 20px;
  height: 20px;
  opacity: 0.5;
}
.content .email span {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 75%);
  background-color: var(--text-white);
  color: var(--text);
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 0.85em;
  letter-spacing: 0px;
}

.social {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 80px;
  margin: min(48px, 32px + 1vw) -32px min(48px, 32px + 1vw) -32px;
  padding: 0px 0px min(48px, 32px + 1vw) 0px;
}
.social > a {
  width: 48px;
  height: 48px;
  background-color: var(--background);
  padding: min(48px, 32px + 1vw);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;
}
.social > a > img {
  width: 32px;
}
.social > a > span {
  position: absolute;
  bottom: -40px;
  display: block;
  width: 150%;
  text-align: center;
  color: var(--background);
  padding: 4px 8px;
  border-radius: 8px;
}